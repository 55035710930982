import React from 'react';
import { Link } from 'gatsby';
import { Formik } from 'formik';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';
import Layout from '../components/Layout';
import Dialog from '../components/Dialog';
import Icon from '../components/Icon';
import { getLocalStorage, AUTH_USER, getAxiosErrorMessage } from '../utils';
import { BASE_PATH, GOOGLE_RECAPTCHA_SITE_KEY } from '../config';
import { FOOD_ROUTE, FARM_ROUTE, EMAIL_ADDRESS } from '../constants';

const SuccessDialog = ({ dialogOpen, setDialogOpen }) => {
    function handleClose() {
        setDialogOpen(false);
    }
    return (
        <Dialog
            open={dialogOpen}
            onClose={handleClose}
            maxWidth={500}
        >
            <div className='text-center result-dialog-container'>
                <button className='btn close-btn' onClick={handleClose}>
                    <Icon iconName='times' width={18} />
                </button>
                <div className="icon-container">
                    <Icon iconName={"check"} width={60} />
                </div>
                <h5>
                    Success! We've received your message and will get back to you as soon as we can. Feel free to explore our site in the meantime.
                </h5>
                <div className='btn-container grid-container add-width-1'>
                    <div className='grid-item-2 add-padding'>
                        <Link to={FARM_ROUTE} className='btn white-btn'>SEARCH FOR FARMS</Link>
                    </div>
                    <div className='grid-item-2 add-padding'>
                        <Link to={FOOD_ROUTE} className='btn primary-btn'>SEARCH FOR FOOD</Link>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

const ContactPage = () => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const authUser = getLocalStorage(AUTH_USER);
    const initialValues = {
        name: '',
        email: '',
        message: ''
    };
    if (authUser) {
        initialValues.name = authUser.displayName;
        initialValues.email = authUser.email;
    }
    return (
        <Layout pageTitle='Contact us' ogUrl="/contact/">
            <div className='layout-container'>
                <div className='user-form-container paper add-padding-2'>
                    <h3>Contact us</h3>
                    <Formik
                        initialValues={initialValues}
                        validate={({ name, email, message }) => {
                            let errors = {};
                            if (!name) {
                                errors.name = "Please enter your name";
                            }
                            if (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email) === false) {
                                errors.email = "Please enter a valid email";
                            }
                            if (!message) {
                                errors.message =  "Please enter a message";
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting, resetForm }) => {
                            setStatus(null);
                            executeRecaptcha("CONTACT_US")
                            .then(recaptchaToken => {
                                return axios.post(`${BASE_PATH}/contactUs`, { ...values, recaptchaToken })
                            })
                            .then(() => {
                                resetForm();
                                setDialogOpen(true);
                            })
                            .catch((err) => {
                                setStatus(getAxiosErrorMessage(err));
                                setSubmitting(false);
                            });
                        }}
                    >
                        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, status, errors, touched}) => {
                            function renderStatus() {
                                if (status) {
                                    return <div className='status-container text-center error'><span>{status}</span></div>
                                }
                                return undefined;
                            }
                            return (
                                <>
                                {renderStatus()}
                                <form onSubmit={handleSubmit}>
                                    <input
                                        name='name'
                                        className={`form-input${touched.name && errors.name ? ' invalid' : ''}`}
                                        type='text'
                                        placeholder='Full name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />
                                    {touched.name && errors.name && <div className='error-msg'>{errors.name}</div>}
                                    <input
                                        name='email'
                                        className={`form-input${touched.email && errors.email ? ' invalid' : ''}`}
                                        type='email'
                                        placeholder='Email'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                    {touched.email && errors.email && <div className='error-msg'>{errors.email}</div>}
                                    <textarea
                                        name='message'
                                        className={`form-input${touched.message && errors.message ? ' invalid' : ''}`}
                                        type='text'
                                        placeholder='How can we help you?'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.message}
                                    />
                                    {touched.message && errors.message && <div className='error-msg'>{errors.message}</div>}
                                    <button type='submit' className={`btn primary-btn${isSubmitting ? ' disabled' : ''}`} disabled={isSubmitting}>
                                        {isSubmitting ? <span className='saving'>SUBMITTING<span>.</span><span>.</span><span>.</span></span> : <span>SUBMIT</span>}
                                    </button>
                                </form>
                            </>
                            )
                        }}
                    </Formik>
                </div>
                <div className='user-form-info'>
                    <p>You can also email us at <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a></p>
                </div>
            </div>
            <SuccessDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
        </Layout>
    )
}

export default () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY}>
            <ContactPage />
        </GoogleReCaptchaProvider>
    )
}
